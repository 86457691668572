import { Link } from "react-router-dom";
import { Button } from "@mui/joy";

function Welcome() {

    return (
        <div className="welcomePage">
            <div className="logoTitle">
                <h1>Welcome
                    <div className="toTitle">to</div>
                    <div>
                        <svg width="249" height="75" viewBox="0 0 249 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_366_8901)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.25H14.5V58.75H43.375V73.25H0V1.25Z" fill="#F9FCFF" />
                        <path d="M126.681 26.2476L126.653 26.2731L126.678 26.2726L126.675 26.2975L126.703 26.2721L148.906 25.8302L129.187 4.18164L126.681 26.2476Z" fill="#255D97" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M174.565 21.8509C182.621 30.7146 181.966 44.4307 173.103 52.4868C164.239 60.5429 150.523 59.8882 142.467 51.0246L131.552 60.9453C145.087 75.8372 168.131 76.9372 183.023 63.402C197.915 49.8669 199.015 26.8222 185.48 11.9302C171.945 -2.96174 148.9 -4.06165 134.008 9.47349L143.929 20.3887C152.793 12.3326 166.509 12.9873 174.565 21.8509Z" fill="#255D97" />
                        <path d="M116.022 47.1801L116.049 47.1534L116.024 47.155L116.026 47.1299L115.999 47.1566L93.8371 48.5839L114.498 69.3356L116.022 47.1801Z" fill="#255D97" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M68.2638 22.5929C59.7741 31.0421 59.7413 44.7738 68.1905 53.2635C76.6398 61.7532 90.3715 61.786 98.8612 53.3368L109.266 63.7915C95.0024 77.9872 71.9315 77.9321 57.7358 63.6684C43.5401 49.4047 43.5952 26.3339 57.8589 12.1382C72.0397 -1.97509 95.0749 -2.33348 109.352 12.0124C115.005 17.6919 122.324 26.4022 127.567 33.325C132.535 39.886 140.29 48.782 142.614 51.1065L132.185 61.5363C129.259 58.6108 121.063 49.1686 115.808 42.2297C110.827 35.6524 103.944 27.4877 98.8976 22.4172C90.5302 14.0097 76.8363 14.0611 68.2638 22.5929Z" fill="#255D97" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M145.275 54.0713C145.274 54.0712 145.337 54.0713 145.275 54.0713L135.4 64.6338C132.9 62.8213 130.6 60.2189 129.4 58.8213C127.628 56.7588 126.245 55.2764 124.4 53.0713C117.462 45.1338 116.837 43.5713 115.65 42.1963C115.361 41.8618 114.962 41.2744 114.587 40.8213C113.34 39.3139 106.048 30.5329 100.4 24.1344L110.275 13.1338C116.962 19.5713 124.775 29.6338 126.195 31.5743C126.411 31.835 126.648 32.1211 126.903 32.4292V32.4302C128.132 33.9147 129.784 35.9122 131.577 38.0704C134.913 42.1521 141.759 50.1625 145.275 54.0713Z" fill="#255D97" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M198.75 1.25V73.2501H213.25V51.5H220.312C228.672 51.5 235.904 49.3041 241.147 44.7328C246.486 40.0777 249 33.6154 249 26.75C249 19.7217 246.189 13.1808 240.936 8.46126C235.71 3.76683 228.51 1.25 220.25 1.25L216.188 1.25002H216.187H213.25L198.75 1.25ZM213.25 15.25V37.5H220.312C226.328 37.5 229.94 35.9296 231.947 34.1801C233.857 32.5146 235 30.1019 235 26.75C235 23.5611 233.779 20.852 231.58 18.8758C229.352 16.8745 225.678 15.25 220.25 15.25H216.188H213.25Z" fill="#F9FCFF" />
                    </g>
                    <defs>
                        <clipPath id="clip0_366_8901">
                            <rect width="249" height="75" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                    </div>
                    </h1>
            </div>
            <Link className="linkComponent getStarted" to={"/name"}><Button size="lg" className="getStarted">Get Started</Button></Link>
        </div>
    );
}

export default Welcome;